.top, .middle, .bottom {
    width: 100%;
    height: 3px;
    position: absolute;
    background: #fff;
    transition: all 0.25s ease-in-out;
    border-radius: 50px;
  }

  .middle {
    opacity: 1;
    top: 11px;
  }

  .top {
    top: 0;
  }

  .top.is-open {
    top: 11px;
    transform: rotate(45deg);
    background: #4a4a4a;
  }

  .middle.is-open {
    opacity: 0;
  }

  .bottom {
    bottom: 0;
  }

  .bottom.is-open {
    bottom: 11px;
    transform: rotate(-45deg);
    background: #4a4a4a;
  }
  