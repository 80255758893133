html {
    box-sizing: border-box;
    overflow-x: hidden!important;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw!important;
    overflow-x: hidden!important;
    font-family: Roboto;
    min-height: 100%;
}

h1, h2, h3, h4, h5 {
    margin: 8px 0;
    padding: 0;
    color: #4A4A4A;
}

h2.script, h1.script, h3.script {
    font-family: Niconne;
    font-size: 3rem;
    color: #B2938B;
}

.script {
    font-family: Niconne; 
}

h1.tab:before, h2.tab:before, h3.tab:before, h4.tab:before, p.tab:before {
    content: "";
    border-left: 4px solid #B2938B;
    margin-left: -14px;
    padding-right: 10px;
  }

p {
    line-height: 26px;
    color: #6C6C6C;
    font-size: 16px
}

a.active, a, .a {
    color: #B2938B;
    display: inline;
    border-bottom: 2px solid #B2938B;
    cursor: pointer;
    transition: all .25s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}

a.active:hover, a:hover {
    color: #906A60;
    border-bottom: 2px solid rgba(245, 166, 35, 0);
}

a.no-underline {
    border: 0!important;
}

.custom-dropdown-buttons {
    border: 0!important;
    color: #B2938B;
    display: inline;
    border-bottom: 2px solid #B2938B;
    cursor: pointer;
    transition: all .25s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}

.custom-dropdown-buttons:hover {
    color: #906A60;
}

.no-icon {
    padding: 2px 16px!important;
}

.icon {
    padding-left: 16px;
}

.hero-wave {
    position: relative;
    z-index: 5;
    width: 100%;
    top: 89%;
}

.hero-cta {
    position: relative;
    border: 0;
    margin-bottom: 64px;

}

.main-body {
    position: relative;
    width: 90%;
    margin-left: 5%;
    /* top: 40px; */
    z-index: 10;
}

.page-vs-nav {
    /* margin-top: 280px; */
    margin-top: 50px;
}

.subtext {
    color: #6C6C6C;
    font-size: 14px;
    font-style: italic;
}

input, textarea, select {
    position: relative;
    color: #6C6C6C;
    padding: 11px 8px;
    margin-right: 16px;
    border: 1px solid #B2938B;
    border-radius: 3px;
    font-size: 16px;

}

textarea {
    min-height: 200px;
 
}

input[type=checkbox]:checked + label {
    background: #B2938B;
    font-weight: bold;
    color: white;
}


.sale-sort-button-spacing {
    display: block;
    z-index: 15;
    padding-bottom: 40px;
    position: relative;
    padding: 0 40px 40px 40px;
}

.slick-slider {
    height: auto;
    position: fixed;
    z-index: 200;
    top: 4%!important;
}

.slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: #fff!important;
    font-size: 10px;
}

@media (min-width: 320px) {
    .slick-slider {
        width: 70%;
        height: auto;
        z-index: 200;
        left: 15%;
    }
}

@media (min-width: 375px) {
    .slick-slider {
        width: 86%;
        height: auto;
        z-index: 200;
        left: 7%;
    }
}

@media (min-width: 567px) {
    .slick-slider {
        width: 66%;
        left: 17%;
        height: auto;
        z-index: 200;
    }
}

@media (min-width: 710px) {
    .slick-slider {
        width: 58%;
        height: auto;
        z-index: 200;
        left: 21%;
    }
}

@media (min-width: 800px) {
    .slick-slider {
        width: 50%;
        height: auto;
        z-index: 200;
        left: 25%;
    }
}

@media (min-width: 940px) {
    .slick-slider {
        width: 40%;
        height: auto;
        z-index: 200;
        left: 30%;
    }
}

@media (min-width: 1100px) {
    .slick-slider {
        width: 30%;
        height: auto;
        z-index: 200;
        left: 35%;
    }
}

.image-slide {
    width: 100%!important;
    position: relative;
    height: auto!important;
    display: inline-block;
}

.image-slide img {
    display: block;
}

.slick-slide .slick-active .slick-current img {
    width: 90%;
    position: relative;
}

.slick-next, .slick-prev {
    display: none;
}

.slick-prev {
    left: 20px;
    z-index: 300;
    width: 40px;
    height: 40px;
}

.slick-next:before, .slick-prev:before {
    font-size: 40px !important;
    color: #906A60;
    opacity: 1;
    transition: all .25s ease-in-out;
 }

.slick-next {
    left: calc(100% - 57px);
    z-index: 300;
    width: 40px;
    height: 40px;
    
}

.slick-slide.slick-active.slick-center.slick-current {
    width: 280px!important;
}

@media (min-width: 920px) {

    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
    }

    .col-50 {
        width: 50%;
    }
    
}

.promotion-container{
    background: #F6F6F6;
    margin-bottom: 40px;
}

.promotion {
    max-width: 500px;
    width: 100%;
    height: auto;
    position: relative;
    display: block;
    z-index: 5;
    margin: auto;
}